// .e-button-tabs {
//     @apply flex;
    .e-button {
        @apply p-3 m-1 h-14 w-72 rounded-lg cursor-pointer flex justify-between items-center bg-teal-50 hover:bg-teal-100;

        .label {
            @apply text-lg font-semibold;
        }

        .sub-label {
            @apply text-sm text-slate-400;
        }

        // FOR SMALLER TABS
        ~ .small {
            @apply h-12 w-56;

            .label {
                @apply text-sm;
            }

            .sub-label {
                @apply text-xs;
            }
        }

        // FOR ACTIVE TAB
        ~ .active {
            @apply bg-blue-600 text-white;

            .sub-label {
                @apply text-white;
            }
        }
    }
// }
