.news-listing-container {
    // @apply grid grid-cols-4 2xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 gap-2;
    .news-list-item {
      @apply w-full mb-2 bg-white rounded-xl relative  transition-all duration-500 overflow-hidden;
      .image {
        @apply overflow-hidden w-full h-[160px] bg-slate-100 relative;
        img {
          @apply h-full w-full object-cover bg-slate-500 absolute transition-all duration-500;
        }
      }
      .body {
        @apply p-4 flex  flex-col items-start justify-between gap-2;
      }
  
      .overlay {
        @apply absolute top-0 right-0 bottom-0 left-0 gap-2 flex flex-col justify-center items-center bg-slate-500 bg-opacity-70  z-10 opacity-0 transition-all duration-500 translate-y-4;
      }
      &:hover {
        @apply cursor-pointer shadow-xl shadow-slate-200;
        img {
          @apply scale-105;
        }
        .overlay {
          @apply opacity-100 translate-y-0;
        }
      }
    }
  }