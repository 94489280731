.legend {
  display: flex;
  align-items: end;
  width: 10em;
  flex-direction: column;
  gap: 1rem;

  .item {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .label {
      font-size: 12px;
      font-weight: 500;
      width:fit-content;
    }

    &__Square {
      width: 1rem;
      height: 1rem;
      border-radius: 10%;
    }

    &__orange {
      width: 1rem;
      height: 1rem;
      border-radius: 10%;
      background-color: orange;
    }

    &__green {
      width: 1rem;
      height: 1rem;
      border-radius: 10%;
      background-color: #86C672;
    }
  }
}
