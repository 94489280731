.apexcharts-tooltip {
    border-radius: 5px;
    box-shadow: 2px 2px 6px -4px #999;
    cursor: default;
    font-size: 14px;
    left: 0px !important;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -100px !important;
    display: flex;
    flex-direction: column;
    z-index: 12;
    transition: .15s ease all;
    white-space: pre-wrap !important;
  }