.btn {
  // filled color
  &-light {
    @apply bg-primary-50 text-primary-500;
    &:hover {
      @apply bg-primary-50 text-primary-500;
    }
  }
  &-white {
    @apply bg-transparent text-primary-500;
    &:hover {
      @apply bg-primary-100 text-primary-500;
    }
  }
  &-blue {
    @apply bg-blue-600;
    &:hover {
      @apply bg-blue-700;
    }
  }
  &-green {
    @apply bg-green-600;
    &:hover {
      @apply bg-green-700;
    }
  }
  &-red {
    @apply bg-red-500;
    &:hover {
      @apply bg-red-600;
    }
  }
  &-dark {
    @apply bg-black;
  }
  &-grey {
    @apply bg-slate-500;
    &:hover {
      @apply bg-slate-600;
    }
  }

  // outline color
  &-outline {
    @apply bg-white;

    &-blue {
      @apply border-blue-500 text-blue-600;
      &:hover {
        @apply text-white bg-blue-600;
      }
    }
    &-red {
      @apply border-red-500 text-red-500;
      &:hover {
        @apply text-white bg-red-600;
      }
    }
    &-green {
      @apply border-green-500 text-green-500;
      &:hover {
        @apply text-white bg-green-600;
      }
    }
  }
}
