.nav-item {
    @apply mx-3 p-3  rounded-lg flex items-center text-base font-medium opacity-60 text-white;
    .icon {
        @apply w-6 h-6 mr-3 text-xl flex items-center justify-center;
    }
    &:hover {
        @apply cursor-pointer bg-white bg-opacity-10 opacity-100;
    }
    &.active {
        @apply bg-white opacity-100 text-primary-800 font-semibold;
    }
}