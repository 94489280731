.stat-container {
  @apply flex flex-1 gap-4 items-center p-3 rounded-xl relative;
  .icon {
    @apply  rounded-xl self-center mr-2 p-3 bg-black/5;
    img {
      @apply w-14 h-14 mix-blend-multiply;
    }
  }
  .stat {
    @apply text-3xl font-extrabold text-slate-900;
  }
  .label {
    @apply text-lg font-semibold text-slate-900;
  }
  &:nth-child(1){
    @apply  bg-emerald-100 border-2 border-emerald-100;
    .icon {
      // @apply mix-blend-multiply;
    }
  }

  &:nth-child(2){
    @apply  bg-amber-100 border-2 border-amber-100;
    .icon {
      // @apply text-amber-600;
    }
  }

  &:nth-child(3){
    @apply  bg-blue-100 border-2 border-blue-100;
    .icon {
      // @apply text-blue-600;
    }
  }
}


