.question-container {
  @apply rounded-lg max-w-5xl grid gap-6 p-8 bg-slate-200/75 border;

  .input-item {
    @apply relative h-64 bg-white p-4 pt-10 w-full rounded-md flex items-center;
    &.empty {
      @apply border border-slate-300 border-dashed bg-white transition-all duration-500 pt-4 h-64;
      &:hover {
        @apply border-secondary-500;
      }
    }
  }

  .options-container {
    @apply grid grid-cols-2 gap-3 overflow-hidden;
  }

  .action-icons {
    @apply absolute top-2 right-2 flex gap-1 justify-end;
    a {
      @apply inline-flex bg-secondary-50/30 text-secondary-500 rounded-md w-8 h-8 items-center justify-center transition-all duration-500;
      &:hover {
        @apply bg-secondary-500 text-white shadow-lg shadow-secondary-100;
      }
    }
  }

  .radio-btn {
    @apply absolute top-2 left-2 w-8 h-8 flex items-center justify-center gap-1 z-20;
  }
}
