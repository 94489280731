/* You can add global styles to this file, and also import other style files */
// @import "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap";

@import "./preflight.css";

html {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  touch-action: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

html,
body {
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
  //   Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-family: "Inter", sans-serif;
  @apply subpixel-antialiased font-normal bg-slate-50;
  font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5 {
  @apply mb-0 font-bold;
}
h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl mb-1;
}
h3 {
  @apply text-2xl mb-1;
}
h4 {
  @apply text-xl mb-2;
}
h5 {
  @apply text-lg mb-4 opacity-80;
}
p {
  @apply text-base;
}
small {
  @apply text-sm;
}

// ****************
// COLUMNS
// ****************
.row {
  @apply flex gap-6;
}
.col-1 {
  @apply w-1/12;
}
.col-2 {
  @apply w-2/12;
}
.col-3 {
  @apply w-3/12;
}
.col-4 {
  @apply md:w-4/12;
}
.col-5 {
  @apply md:w-5/12;
}
.col-6 {
  @apply md:w-6/12;
}
.col-7 {
  @apply md:w-7/12;
}
.col-8 {
  @apply w-8/12;
}
.col-9 {
  @apply w-9/12;
}
.col-10 {
  @apply w-10/12;
}
.col-11 {
  @apply w-11/12;
}
.col-12 {
  @apply w-full;
}

.form-label {
  @apply mb-1 font-medium;
  line-height: 21.7px;
}

.breadcrumbs {
  a {
    @apply text-sm font-semibold;
  }
}

.border {
  &--1 {
    border-top: 1px solid;
    border-color: #eeeeee20;
  }

  &--sidenav-sub-menu {
    border-bottom: 1px solid;
    border-color: #eeeeee20;
  }

  &--top {
    border-top: 1px solid;
    border-color: #101b3720;
  }

  &--bottom {
    border-bottom: 1px solid;
    border-color: #101b3720;
  }
}

.mantine-Avatar-image {
  object-fit: contain !important;
}

// OVERRIDERS
.mantine-Tabs-tabLabel {
  @apply text-base font-semibold;
}
.mantine-Modal-header {
  @apply font-bold text-xl;
}

.page-layout {
  @apply relative h-full min-h-full;
  .layout-body {
    @apply pb-36 pt-8;
  }
  .footer {
    width: calc(100vw - 250px);
    @media (min-width: 1280px) {
      width: calc(100vw - 300px);
    }
    @apply py-3 px-10 fixed bottom-0 right-0 bg-white z-10; // border-t border-dark border-opacity-20
    border-top: 1px solid #101b3720;
  }
}

.filter-container {
  @apply flex p-6 bg-gray-50 rounded-xl  border border-gray-200 shadow-inner;
}

.map-link-container {
  @apply  flex items-center  overflow-hidden flex-shrink-0 gap-1;
  a.map-link {
    @apply m-0  text-primary-500 text-sm font-semibold p-2 py-1.5 rounded-md bg-primary-50;
    &:last-child {
      @apply border-0;
    }
    &:hover {
      @apply bg-primary-100 shadow-inner;
    }
  }
}


// BUTTON
.btn {
  @apply p-3.5 px-5 rounded bg-primary-600 text-white font-semibold hover:bg-primary-700;
}