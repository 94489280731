table.e-table {
  thead {
    tr {
      // @apply border-b-2 border-primary-400;
      @apply border-b-2 border-slate-800;
      th {
        @apply text-dark py-1 text-center;
      }
    }
  }
  tbody {
    tr {
      td {
        @apply text-base;
      }
    }
  }
  &.end-btns {
    tbody {
      tr {
        td:last-of-type {
            div {
                @apply flex justify-end gap-2;
                svg {
                  @apply w-5 h-5 opacity-30;
                  &:hover {
                    @apply opacity-100  cursor-pointer text-primary-500;
                  }
                  &.delete {
      
                      &:hover {
                          @apply text-danger;
                      }
                  }
                }
            }
          
        }
      }
    }
  }
}
