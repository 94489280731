.apexcharts-legend-text *, .apexcharts-legend-marker * {
    pointer-events: all !important; 
}
.apexcharts-legend.apexcharts-align-center.apx-legend-position-top{
    overflow: visible !important;
}
.apexcharts-legend-series{
    overflow: visible !important;
}
.apexcharts-legend-text{
    display: flex;
}

.testing{
    position: relative;
}

.testingInside{
    position:absolute;
    bottom:120%;
    right:0px;
    display: none;
    font-family: Inter,sans-serif;
    background-color: #212529;
    color: #fff;
    line-height: 1.55;
    font-size: 14px;
    border-radius: 4px;
    padding: calc(10px / 2) 10px;
    position: absolute;
    white-space: unset;
    pointer-events: none;
    width: 220px;
    z-index: 99;
}

.testing:hover .testingInside{
    display:block;
}

.apexcharts-canvas svg {
    overflow: visible !important;
}

.apexcharts-canvas foreignObject {
    overflow: visible !important;
}
