.card {
  @apply rounded-xl border border-slate-200 overflow-hidden bg-white my-6;
  &--head {
    @apply flex justify-between items-center  p-6 pt-6;
    .title {
      @apply text-xl font-bold text-blue-900;
    }
  }
  &--body {
    @apply p-6 pt-2;
  }
  &--foot {
    @apply p-4 py-2 border-t border-slate-200;
  }
}