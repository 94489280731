.navbar.collapsed {
  width: 76px;
  box-shadow: 0 0 40 #051a4e50;
  .nav-item {
    .icon {
      @apply mr-0;
    }
    .dropdown-icon {
      @apply hidden w-0;
    }
  }
}

.navbar {
  background: url(./images/bg-nav.jpg) center center;
  @apply h-full bg-cover text-white flex flex-col transition-all duration-500;
  width: 300px;
  @media (min-width: 1280px) {
    width: 300px;
  }
  .head {
    @apply p-4;
    .avatar-group {
      @apply gap-3 flex items-center justify-between;
    }
  }
  .body {
    @apply flex-1 overflow-y-auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    touch-action: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .foot {
    @apply flex flex-col;
  }
}
